const bAllowance = [
    { value: '0.00' },
    { value: '0.01' },
    { value: '0.02' },
    { value: '0.03' },
    { value: '0.04' },
    { value: '0.05' },
    { value: '0.06' },
    { value: '0.07' },
    { value: '0.08' },
    { value: '0.09' },
    { value: '0.10' },
];
const stitches = [
    { value: '2.0' },
    { value: '2.5' },
    { value: '3.0' },
    { value: '3.5' },
    { value: '4.0' },
    { value: '4.5' },
    { value: '5.0' },
    { value: '5.5' },
    { value: '6.0' },
    { value: '6.5' },
    { value: '7.0' },
    { value: '7.5' },
    { value: '8.0' },
    { value: '8.5' },
    { value: '9.0' },
    { value: '9.5' },
    { value: '10.0' },
    { value: '0.0' },
    { value: '11.0' },
    { value: '12.0' },
    { value: '13.0' },
    { value: '14.0' },
    { value: '15.0' },
    { value: '16.0' },
    { value: '17.0' },
    { value: '18.0' },
    { value: '19.0' },
    { value: '20.0' },
];

const proSubAnalysisColumns = [
    { field: 'seqNo', header: 'S.No.' },
    { field: 'code', header: 'Code' },
    { field: 'frequency', header: 'Freq.' },
    { field: 'desc', header: 'Description' },
    { field: 'tmu', header: 'TMU' },
    { field: 'extTmu', header: 'Ext TMU' },
    { field: 'allowance', header: 'Allowance' },
    { field: 'tmuAllowed', header: 'TMU Allowed' },
];

const proAnalysisHistoryColumns = [
    { field: 'seqNo', header: 'OP.No.' },
    { field: 'code', header: 'Description' },
    { field: 'frequency', header: 'SMV' },
    { field: 'desc', header: 'Approved By' },
    { field: 'tmu', header: 'Time of Action' },
    { field: 'extTmu', header: 'Action' },
    { field: 'allowance', header: 'Grid-cat' },
    { field: 'tmuAllowed', header: 'Size' },
    { field: 'tmuAllowed', header: 'Stage' },
];

export { proSubAnalysisColumns };
export { proAnalysisHistoryColumns };
export { bAllowance };
export { stitches };
