const sequenceTableCol = [
    { field: 'description', header: 'Description' },
    { field: 'operation_number', header: 'OP Number' },
    { field: 'std_time', header: 'STD. Time' }
];
const sequenceTableAllCol = [
    { field: 'description', header: 'Description' },
    { field: 'operation_number', header: 'OP Number' },
    { field: 'std_time', header: 'STD. Time' },
    { field: 'subSection', header: 'Sub section' }
];
const copySequenceCol = [
    { field: 'seqNo', header: 'Seq No.' },
    { field: 'description', header: 'Description' },
    { field: 'std_time', header: 'STD. Min' },
    { field: 'subSection', header: 'Sub Section' },
    { field: 'ppName', header: 'Progress Point' },
    { field: 'operation_number', header: 'OP Number' },
    { field: 'group', header: 'Group' },
    { field: 'remark', header: 'Remark' }
];
const versionCols = [
    { field: 'seqNo', header: 'Seq' },
    { field: 'seqNo', header: 'Descr' },
    { field: 'seqNo', header: 'OP No.' },
    { field: 'seqNo', header: 'Sub. Name' },
    { field: 'seqNo', header: 'PP Name' },
    { field: 'seqNo', header: 'Group Name' }
];

export { sequenceTableCol };
export { sequenceTableAllCol };
export { copySequenceCol };
export { versionCols };
