import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpclientService } from '../shared/httpclient.service';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable({
  providedIn: 'root'
})

export class FactoryService {

  constructor(private http: HttpClient, private httpClientService: HttpclientService, private httpService: HttpClient) { }

  getFactory() {
    const url = `${window.location.origin}/apigateway/admin/api/v1/factory/list`;
    return this.httpClientService.get(url);
  }

  createFactory(data) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/register/factory`;
    return this.httpClientService.post(url, data);
  }

  updateFactory(data) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/update/factory`;
    return this.httpClientService.put(url, data);
  }

  deleteFactory(factoryId) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/delete/factory?factoryId=`+factoryId;
    return this.httpClientService.remove(url, factoryId);
  }

  statusFactory(factoryId: number, status: boolean) {
    const url = `${window.location.origin}/apigateway/admin/api/v1/set/factory/status?factoryId=${factoryId}&active=${status}`;
    return this.httpClientService.get(url);
  }

search(companyName) {
    var listOfCompany = this.httpService.get('/apigateway/admin/api/v1/auto/complete/company?companyName=' + companyName)
    .pipe(
        debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map(
            (data: any) => {
                return (
                    data.length != 0 ? data as any[] : [{'Company Name': 'No Company Found'} as any]
                );
            }
    ));
    return listOfCompany;
}

searching(roleName: any, companyId: any) {
  var listOfrole = this.httpService.get(`/apigateway/admin/api/v1/auto/complete/company/role?roleName=${roleName}&companyId=${companyId}`)
  .pipe(
      debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
      map(
          (data: any) => {
              return (
                  data.length != 0 ? data as any[] : [{'Role Name': 'No Role Found'} as any]
              );
          }
  ));
  return listOfrole;
}

createUser(data) {
  const url = `${window.location.origin}/apigateway/admin/api/v1/register/user`;
  return this.httpClientService.post(url, data);
}

searchFactory(factoryName) {
  var listOfFactory = this.httpService.get('/apigateway/admin/api/v1/auto/complete/factory?factoryName=' + factoryName)
  .pipe(
      debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
      map(
          (data: any) => {
              return (
                  data.length != 0 ? data as any[] : [{'factory Name': 'No factory Found'} as any]
              );
          }
  ));
  return listOfFactory;
}
}
