import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { RolesService } from './roles.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

const keys = ['canAccess', 'canApprove', 'canDelete', 'canPrint', 'canSave', 'canSendForApproval',
  'canUpdate', 'canDisapprove', 'canView', 'canViewCosting', 'canViewFit', 'canViewPP', 'canViewProduction', 'canViewSetSize'];

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./roles.component.sass']
})
export class RolesComponent implements OnInit {
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  rolesList: Array<any> = [];
  spinner = false;
  companyId: number;
  moduleList: Array<any> = [];
  roleName: string;
  existingRolePermission: any;
  isUpdate = false;
  selectedColumn: any = {};
  lastSelected: number;
  roleSelected: boolean;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private rolesService: RolesService,
    private toastr: ToastrService,
  ) {
    // this.selectedColumn.canView = false;
  }

  ngOnInit() {
    this.getFactoryId();
    this.getSubmoduleList();
  }

  /**
   * get company id
   */
  getFactoryId() {
    this.spinner = true;
    this.rolesService.getFactoryId().subscribe((response: any) => {
      this.companyId = response.data[0].companyId;
      this.getRoleList();
      this.spinner = false;
    });
  }

  /**
   * get role list
   */
  getRoleList() {
    this.spinner = true;
    this.rolesService.getRoles(this.companyId).subscribe((response: any) => {
      if (response.responseObject) {
        this.rolesList = response.responseObject;
        for (const iterator of this.rolesList) {
          iterator.isClicked = false;
        }
      }
      this.spinner = false;
    });
  }

  /**
   * get module and sub module list
   */
  getSubmoduleList() {
    this.spinner = true;
    this.rolesService.getModules().subscribe((response: any) => {
      if (response.responseObject) {
        this.moduleList = response.responseObject;
        for (const modul of this.moduleList) {
          for (const subModule of modul.subModules) {
            subModule.canAccess = false;
            subModule.canApprove = false;
            subModule.canDelete = false;
            subModule.canPrint = false;
            subModule.canSave = false;
            subModule.canSendForApproval = false;
            subModule.canUpdate = false;
            subModule.canView = false;
            subModule.canViewCosting = false;
            subModule.canViewFit = false;
            subModule.canViewPP = false;
            subModule.canViewProduction = false;
            subModule.canViewSetSize = false;
          }
        }
      }
      this.spinner = false;
    });
  }

  /**
   * get roles permission
   */
  getRolesPermission(row, index) {
    this.spinner = true;
    this.roleSelected = false;
    if (this.lastSelected) {
      this.rolesList[this.lastSelected].editted = false;
    }
    if (!row.isClicked) {
      this.rolesList[index].editted = true;
      this.roleSelected = true;
      this.rolesService.getRolesPermission(row.id).subscribe((response: any) => {
        row.isClicked = true;
        this.isUpdate = true;
        if (response.data) {
          this.existingRolePermission = response.data;
          this.roleName = this.existingRolePermission[0].roleName;
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.existingRolePermission.length; i++) {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < this.moduleList.length; j++) {
              for (let k = 0; k < this.moduleList[j].subModules.length; k++) {
                if (this.moduleList[j].subModules[k].subModuleId === this.existingRolePermission[i].subModuleDTO.id) {
                  this.moduleList[j].subModules[k] = this.existingRolePermission[i];
                  this.moduleList[j].subModules[k].subModuleName = this.existingRolePermission[i].subModuleDTO.subModuleName;
                  this.initColumnCheckAll();
                  this.initRowCheck();
                }
              }
            }
          }
        }
        this.spinner = false;
      });
    } else {
      row.isClicked = false;
      this.roleName = '';
      this.isUpdate = false;
      this.spinner = false;
      this.getSubmoduleList();
    }
    this.lastSelected = index;
  }

  /**
   * check selected column
   */
  initColumnCheckAll() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.moduleList.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.moduleList[i].subModules.length; j++) {
        // tslint:disable-next-line:prefer-for-of
        for (let k = 0; k < keys.length; k++) {
          if (!this.moduleList[i].subModules[j][keys[k]]) {
            this.moduleList[i][keys[k]] = false;
          } else {
            this.moduleList[i][keys[k]] = true;
          }
        }
      }
    }
  }

  /**
   * Row check
   */
  initRowCheck() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.moduleList.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.moduleList[i].subModules.length; j++) {
        // tslint:disable-next-line:prefer-for-of
        const all = [];
        for (let k = 0; k < keys.length; k++) {
          if (this.moduleList[i].subModules[j][keys[k]]) {
            all.push(keys[k]);
          }
        }
        if (keys.length === all.length) {
          this.moduleList[i].subModules[j]['allSelected'] = true;
        } else {
          this.moduleList[i].subModules[j]['allSelected'] = false;
        }
      }
    }
  }

  /**
   * save permission
   */
  savePermissions() {
    this.spinner = true;
    const subList = [];
    for (const moduleData of this.moduleList) {
      for (const subModule of moduleData.subModules) {
        subList.push(subModule);
      }
    }
    const data = {
      companyId: this.companyId,
      roleName: this.roleName,
      subModulePermissionForms: subList
    };
    if (this.isUpdate) {
      this.rolesService.updateRolesPermission(data).subscribe((response: any) => {
        this.spinner = false;
        this.getRoleList();
        this.getSubmoduleList();
        this.roleName = '';
      });
    } else {
      this.rolesService.saveRolesPermission(data).subscribe((response: any) => {
        this.spinner = false;
        this.getRoleList();
        this.getSubmoduleList();
        this.roleName = '';
      });
    }

  }

  /**
   * select all
   */
  selectAll(index, clicked) {
    // for (const moduleList of this.moduleList) {
    for (const subModule of this.moduleList[index].subModules) {
      subModule.canAccess = clicked;
      subModule.canApprove = clicked;
      subModule.canDelete = clicked;
      subModule.canPrint = clicked;
      subModule.canSave = clicked;
      subModule.canSendForApproval = clicked;
      subModule.canUpdate = clicked;
      subModule.canView = clicked;
      subModule.canViewCosting = clicked;
      subModule.canViewFit = clicked;
      subModule.canViewPP = clicked;
      subModule.canViewProduction = clicked;
      subModule.canViewSetSize = clicked;
      subModule.canDisapprove = clicked;
    }
    // }
  }

  /**
   * select row
   */
  selectRow(moduleIndex, rowIndex, clickedValue) {
    const list = this.moduleList[moduleIndex].subModules[rowIndex];
    list.canAccess = clickedValue;
    list.canApprove = clickedValue;
    list.canDelete = clickedValue;
    list.canPrint = clickedValue;
    list.canSave = clickedValue;
    list.canSendForApproval = clickedValue;
    list.canUpdate = clickedValue;
    list.canView = clickedValue;
    list.canViewCosting = clickedValue;
    list.canViewFit = clickedValue;
    list.canViewPP = clickedValue;
    list.canViewProduction = clickedValue;
    list.canViewSetSize = clickedValue;
    list.canDisapprove = clickedValue;
  }

  /**
   * select column
   */
  selectColumn(moduleIndex, key, clicked) {
    for (const subModule of this.moduleList[moduleIndex].subModules) {
      subModule[key] = clicked;
    }
  }

  checkAll(moduleIndex, key) {
    const subModules = this.moduleList[moduleIndex].subModules;
    let keyExist;
    this.selectedColumn[key] = false;
    for (const data of subModules) {
      if (!data[key]) {
        keyExist = true;
        break;
      }
    }
    if (!keyExist) {
      this.selectedColumn[key] = true;
      // alert('All checked');
    }
  }

  checkRowAll(moduleIndex, subModuleIndex, key) {
    // const subModules = this.moduleList[moduleIndex].subModules;
    let keyExist;
    // this.selectedColumn[key] = false;
    // for (const data of subModules) {
    if (!this.moduleList[moduleIndex].subModules[subModuleIndex][key]) {
      keyExist = true;
    }
    // }
    if (!keyExist) {
      this.moduleList[moduleIndex].subModules[subModuleIndex]['allSelected'] = true;
      // alert('All checked');
    } else {
      this.moduleList[moduleIndex].subModules[subModuleIndex]['allSelected'] = false;
    }
  }

  /**
   * delete role
   */
  deleteRole(roleId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        this.rolesService.deleteRole(roleId).subscribe((response: any) => {
          this.spinner = false;
          this.getRoleList();
          this.getSubmoduleList();
          this.roleName = '';
        });
      }
    });
  }

}
