import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpclientService } from '../shared/httpclient.service';
import { MachineService } from './machine.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Http } from '@angular/http';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-machine',
  templateUrl: './machine.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./machine.component.sass']
})
export class MachineComponent implements OnInit {
  displayMode = 1;
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  userListDTO: any;
  brandListDTO: any;
  factoryIdDTO: any;
  stitchIdDTO: any;
  createNewBrand: any = {};
  createMachine: any = {};
  createStitch: any = {};
  machineUpdate: any = {};
  searchCompany: FormControl = new FormControl();
  companySearchList: Array<any> = [];
  text: string;
  results: Array<any> = [];
  resultsFactory: Array<any> = [];
  resultsRole: Array<any> = [];
  companyId: any;
  id: any;
  coList: any;
  roleList: any;
  factoryList: any;
  optionSelected: string;
  searchText: any;
  uploadImgUrl: string;
  imageSrc: any;
  documentId: any;
  spinner: boolean;
  editStitchData: any = {};
  machineAuthData: any = {};
  stitchAuthData: any = {};

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private machineService: MachineService,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private service: MachineService,
    public sharedService: SharedService
  ) {
    this.optionSelected = '';
    this.createMachine.machineName = null;
    this.createMachine.factoryId = null;
    this.createMachine.machineColor = null;
    this.createMachine.stitchClassId = null;
    this.createMachine.rpm = null;
    this.createMachine.allowance = null;
    this.createMachine.machineType = null;
    this.createStitch.threadRatio = null;
    this.createStitch.factoryId = null;
    this.createStitch.looperThread = null;
    this.createStitch.needleThread = null;
    this.createStitch.description = null;
    this.createStitch.needleCount = null;
    this.createStitch.machineType = null;
    this.createStitch.name = null;
    this.machineUpdate.factoryId = null;
    this.uploadImgUrl = `${window.location.origin}/apigateway/style/api/v1/style/image`;
  }

  ngOnInit() {
    this.getMachineList();
    this.getFactoryId();
    this.getStitchClass();
    this.getAuthData();
  }

  /**
   * fetch role and permissions
   */
  getAuthData() {
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'Operation';
      });
      const machineAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Machine';
      });
      const stitchAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'StitchClass';
      });
      this.machineAuthData = machineAuthData;
      this.stitchAuthData = stitchAuthData;
    });
  }

  getMachineList() {
    this.machineService.getMachine().subscribe((response: any) => {
      if (response.data) {
        this.userListDTO = response.data;
      } else {
      }
    });
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  public openNewCompanyModal() {
    this.imageSrc = null;
    this.documentId = null;
    this.createMachine = {};
    this.ngxSmartModalService.getModal('myModal').open();
    this.getStitchClass();
  }

  public openNewStichModal() {
    this.ngxSmartModalService.getModal('myModal1').open();
    this.getStitchClass();
  }

  getFactoryId() {
    this.machineService.getFactoryId().subscribe((response: any) => {
      if (response.data) {
        this.factoryIdDTO = response.data;
      } else {
      }
    });
  }

  getStitchClass() {
    this.machineService.getStitchClass().subscribe((response: any) => {
      if (response.data) {
        this.stitchIdDTO = response.data;
      } else {
      }
    });
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.httpClient.post<any>(this.uploadImgUrl, formData).subscribe(
        (res) => {
          this.imageSrc = res.data.path;
          this.documentId = res.data.id;
        },
      );
    }
  }

  oncreateMachine(form: NgForm) {
    this.createMachine.documentId = this.documentId;
    this.machineService.createMachine(this.createMachine).subscribe(
      result => {
        form.reset();
        this.toastr.success(result.message, 'Success');
        this.getMachineList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  openMachineUpdateModal(data) {
    this.machineUpdate.machineName = data.name;
    this.machineUpdate.machineColor = data.machineColor;
    this.machineUpdate.machineType = data.machineType;
    this.machineUpdate.rpm = data.rpm;
    this.machineUpdate.allowance = data.allowance;
    this.machineUpdate.factoryId = data.factoryId;
    this.machineUpdate.stitchClassName = data.stitchClassName;
    this.machineUpdate.stitchClassId = data.stitchClassId;
    this.machineUpdate.id = data.id;
    this.documentId = data.document.id;
    this.imageSrc = data.document.path;
    this.ngxSmartModalService.getModal('myModal2').open(data);
  }

  oncreateStitch(form: NgForm) {
    this.machineService.createStitch(this.createStitch).subscribe(
      result => {
        form.reset();
        this.toastr.success(result.message, 'Success');
        this.getMachineList();
        this.getStitchClass();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  onCustomerUpdate(form: NgForm) {
    this.machineUpdate.documentId = this.documentId;
    this.machineService.updateMachine(this.machineUpdate, this.machineUpdate.id).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.toastr.success(result.message, 'Success');
        this.getMachineList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  deleteMachine(list) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        this.machineService.deleteMachine(list.id).subscribe((response: any) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          const index: number = this.userListDTO.indexOf(list);
          this.userListDTO.splice(index, 1);
          this.toastr.success(response.message, 'Success');
        }, (error) => {
          this.toastr.error(error.error.message, 'Error');
        });
      }
    });
  }

  deleteStichClass(list) {
    this.machineService.deleteStichClass(list.id).subscribe((response: any) => {
      this.getStitchClass();
      this.toastr.success(response.message, 'Success');
    });
  }

  editStitchClass(list) {
    this.editStitchData.name = list.name;
    this.editStitchData.id = list.id;
    this.editStitchData.description = list.description;
    this.editStitchData.machineType = list.machineType;
    this.editStitchData.needleCount = list.needleCount;
    this.editStitchData.needleThread = list.needleThread;
    this.editStitchData.looperThread = list.looperThread;
    this.editStitchData.threadRatio = list.threadRatio;
    this.editStitchData.factoryId = list.factory.id;
    this.ngxSmartModalService.getModal('editStichClass').open();
  }

  /**
   * update stich class
   */
  onEditStitch() {
    this.spinner = true;
    this.machineService.updateStitch(this.editStitchData).subscribe((response) => {
      this.spinner = false;
      this.toastr.success(response.message, 'Success');
      this.ngxSmartModalService.getModal('editStichClass').close();
      this.getStitchClass();
    });
  }
}
