import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { StylesService } from './styles.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Http } from '@angular/http';
import { FormControl } from '@angular/forms';

import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../shared/shared.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))])
  ],
  styleUrls: ['./styles.component.sass']
})
export class StylesComponent implements OnInit {
  displayMode = 1;
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  userStylesList: Array<any> = [];
  brandListDTO: any;
  factoryIdDTO: any;
  stitchIdDTO: any;
  url = '';
  createNewBrand: any = {};
  createStyles: any = {};
  editStyles: any = {};
  createRangeData: any = {};
  editRangeData: any = {};
  createStitch: any = {};
  machineUpdate: any = {};
  searchCompany: FormControl = new FormControl();
  companySearchList: Array<any> = [];
  text: string;
  results: Array<any> = [];
  resultsFactory: Array<any> = [];
  resultsRole: Array<any> = [];
  companyId: any;
  id: any;
  coList: any;
  roleList: any;
  factoryList: any;
  optionSelected: string;
  searchText: any;
  rangeName: string;
  categoryName: string;
  sizeGridName: string;
  rangeData: any = [];
  categoryData: any = [];
  rangelists: any;
  rangeListsOptions: any[];
  catList: any;
  categoryListsOptions: any[];
  sizeGridData: any;
  sizeGridId;
  sizeValue: string;
  sizeData: any;
  sizeGridList: any;
  availableSizeGrid: any = [];
  sizeGridOptions: any[];
  sizeList: any;
  customerOptions: any[];
  createSizeGridData: any = {};
  createCategoryData: any = {};
  customerId: any;
  availableBrands: any;
  customer: string;
  range: string;
  category: string;
  sizeGrid: any;
  createsizeData: any = {};
  existingStyleId: any;
  editStyleModal: boolean;
  styleModalType: string;
  styleModalButton: string;
  uploadImgUrl: string;
  imageSrc: any;
  documentId: any;
  spinner: boolean = true;
  uploadedImages: Array<any> = [];
  lastSelectedIndex: any;
  editSieGrid: any = {};
  editSizeData: any = {};
  isEditRange = false;
  rangeId: any;
  isEditCategory: boolean;
  categoryId: any;
  display = false;
  styleImages: Array<any> = [];
  selectedImg: any;
  documentUploadURL = `${window.location.origin}/apigateway/style/api/v1/style/document`
  uploadTechPack: any = {};
  searchedStyleDebounce = _.debounce(this.debounceSearch, 700);
  page: number;
  size: number;
  totalStyleCardData : number;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private stylesService: StylesService,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public sharedService: SharedService
  ) {
    this.optionSelected = '';
    this.sizeGridId = null;
    this.customer = '';
    this.range = '';
    this.category = '';
    this.sizeGrid = '';
    this.uploadImgUrl = `${window.location.origin}/apigateway/style/api/v1/style/image`;
    this.uploadedImages = [];
    this.editSieGrid.gridValue = '';
    this.editSizeData.gridValue = '';
  }

  ngOnInit() {
    this.getStyleList(1,8);
    this.getFactoryId();
    // this.getAuthData();
  }

  // /**
  //  * fetch role and permissions
  //  */
  // getAuthData() {
  //   this.spinner = true;
  //   const auth = this.sharedService.authData$.subscribe((data: any) => {
  //     const authData = data.find((el) => {
  //       return el.name === 'Operation';
  //     });
  //     const opSeqAuthData = authData.subModules.find((el) => {
  //       return el.subModuleName === 'Operation Sequence';
  //     });
  //     const subAuthData = authData.subModules.find((el) => {
  //       return el.subModuleName === 'Subsection';
  //     });
  //     this.opSeqAuthData = opSeqAuthData;
  //     this.subAuthData = subAuthData;
  //     this.spinner = false;
  //   });
  // }

  /**
   * open image modal
   */

   paginate(event) {
     this.page = (event.first / event.rows);
     this.size = event.rows;
     this.getStyleList(this.page,this.size);
}
  styleImageModal(imgData) {
    this.styleImages = [];
    for (const iterator of imgData.documents) {
      if (iterator.documentType === 'IMAGE') {
        this.styleImages.push(iterator);
      }
    }
    // this.styleImages = imgData.documents;
    this.ngxSmartModalService.getModal('styleImagesModal').open();
  }

  openImageModal(img) {
    this.selectedImg = img.path;
    this.display = true;
  }

  /**
   * 
   */
  redirecToThread() {
    localStorage.setItem('lastPage', this.activatedRoute.url['_value'][0].path);
    this.router.navigate(['threadConsumption']);
  }

  getStyleList(page,size) {
    this.spinner = true;
    this.stylesService.getStyle(page,size).subscribe((response: any) => {
      if (response.data) {
        this.userStylesList = response.data;
        this.totalStyleCardData = response.totalCount;
        this.spinner = false;
      } 
    });
  }

  /**
   * to delete style
   */
  deleteStyle(id): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        this.stylesService.deleteStyle(id).subscribe((response: any) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          this.getStyleList(1,8);
        }, (error) => {
          this.toastr.error(error.error.message, 'Error');
        });
      }
    });
  }

  public openSizeGrid() {
    this.ngxSmartModalService.getModal('myModal5').open();
    this.getFactorySizeGrid();
  }

  public openNewStyleModal() {
    this.createStyles.sellingPrice = null;
    this.createStyles.category = null;
    this.createStyles.brandId = null;
    this.createStyles.categoryId = null;
    this.createStyles.customerId = null;
    this.createStyles.description = null;
    this.createStyles.factoryId = null;
    this.createStyles.merchantname = null;
    this.createStyles.name = null;
    this.createStyles.active = null;
    this.createStyles.rangeId = null;
    this.createStyles.sizeGridId = null;
    this.createStyles.season = null;
    this.createStyles.styleNotes = null;
    this.uploadedImages = [];
    this.ngxSmartModalService.getModal('myModal').open();
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  /**
   * to open edit style form modal
   */
  public openEditStyleModal(data, type) {
    if (type === 'edit') {
      this.styleModalType = 'Edit Style';
      this.styleModalButton = 'Update';
      // this.createStyles.name = data.name;
    } else {
      this.styleModalType = 'Copy Style';
      this.styleModalButton = 'Copy';
      // this.createStyles.name = null;
    }
    this.getCustomersBrand(data.customer.id);
    this.createStyles.active = true;
    this.createStyles.name = data.name;
    this.uploadedImages = data.documents;
    this.createStyles.category = data.category ? data.category.id : '';
    this.createStyles.brandId = data.brand.id;
    this.createStyles.customerId = data.customer.id;
    this.customer = data.customer.name;
    this.category = data.category ? data.category.name : '';
    this.createStyles.description = data.description;
    this.createStyles.factoryId = data.factory.id;
    this.createStyles.merchantname = data.merchantname;
    this.createStyles.rangeId = data.range ? data.range.rangeId : '';
    this.range = data.range ? data.range.name : '';
    this.createStyles.season = data.season;
    this.createStyles.sellingPrice = data.sellPrice;
    this.sizeGrid = data.sizeGrid;
    this.sizeGridId = data.sizeGridId;
    this.createStyles.styleNotes = data.styleNotes;
    this.createStyles.styleId = data.id;
    this.createStyles.needleMin = data.needleMin;
    this.createStyles.costingSam = data.costingSam;
    this.ngxSmartModalService.getModal('editModal').open(data);
  }

  public openNewRangeModal() {
    this.ngxSmartModalService.getModal('myModal7').open();
    this.getRange();
  }

  /**
   * To open create category Modal
   */
  openCreateCategoryModal(): void {
    this.ngxSmartModalService.getModal('categoryModal').open();
    this.getCategory();
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.httpClient.post<any>(this.uploadImgUrl, formData).subscribe(
        (res) => {
          this.uploadedImages.push(res.data);
        },
      );
    }
  }

  removeImg(imgId) {
    const selectedImgIndex = this.uploadedImages.findIndex(x => x.id === imgId);
    this.uploadedImages.splice(selectedImgIndex, 1);
  }

  getFactoryId() {
    this.stylesService.getFactoryId().subscribe((response: any) => {
      if (response.data) {
        this.factoryIdDTO = response.data;
      } else {
      }
    });
  }

  /**
   * function to get Range
   */
  getRange(): void {
    this.stylesService.getRange(this.createStyles.factoryId).subscribe(
      response => {
        this.rangeData = response;
        this.rangeData = this.rangeData.data ? this.rangeData.data : [];
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * function to create Range
   */
  addRange(): void {
    this.createRangeData.factoryId = this.createStyles.factoryId;
    this.createRangeData.name = this.rangeName;
    if (!this.isEditRange) {
      this.stylesService.createRange(this.createRangeData).subscribe(
        result => {
          this.toastr.success('Category created Successfully', 'Success');
          this.rangeName = '';
          this.getRange();
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    } else {
      // this.editRangeData.id;
      // alert('edit h ye' + this.editRangeData.id);
      this.stylesService.updateRange(this.createRangeData, this.editRangeData.rangeId).subscribe(
        result => {
          this.toastr.success('Category Updated Successfully', 'Success');
          this.rangeName = '';
          this.getRange();
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    }
  }

  /**
   * function to get Category
   */
  getCategory(): void {
    this.stylesService.getCategory(this.createStyles.factoryId).subscribe(
      result => {
        this.categoryData = result;
        this.categoryData = this.categoryData.data;
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * function to create new Category
   */
  addCategory(): void {
    // const createCategoryData: any = '';
    this.createCategoryData.factoryId = this.createStyles.factoryId;
    this.createCategoryData.name = this.categoryName;
    if (!this.isEditCategory) {
      this.stylesService.createCategory(this.createCategoryData).subscribe(
        result => {
          this.categoryName = '';
          this.toastr.success('Category created Successfully', 'Success');
          this.getCategory();
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    } else {
      this.stylesService.updateCategory(this.createCategoryData, this.categoryId).subscribe(
        result => {
          this.categoryName = '';
          this.isEditCategory = false;
          this.toastr.success('Category created Successfully', 'Success');
          this.getCategory();
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    }

  }

  /**
   * function to create new Size Grid
   */
  addsizeGrid(): void {
    // const createSizeGridData: any = '';
    this.createSizeGridData.factoryId = this.createStyles.factoryId;
    this.createSizeGridData.gridValue = this.sizeGridName;
    this.stylesService.createSizeGrid(this.createSizeGridData).subscribe(
      result => {
        this.toastr.success('Category created Successfully', 'Success');
        this.getFactorySizeGrid();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * to search for available range
   * @param event search string
   */
  searchRange(event) {
    this.stylesService.searchRange(event.query, this.createStyles.factoryId).subscribe((response: any) => {
      const rangeAvailable = [];
      this.rangelists = response.data;
      for (const rangeOptions of response.data) {
        rangeOptions.name = rangeOptions.name ? rangeOptions.name : 'User';
        rangeAvailable.push(rangeOptions.name);
      }
      this.rangeListsOptions = rangeAvailable;
    });
  }

  /**
   * to get Id of selected range
   * @param event selected option
   */
  captureRangeId(event: any) {
    for (let i = 0; i < this.rangelists.length; i++) {
      if (this.rangelists[i].name === event) {
        this.createStyles.rangeId = this.rangelists[i].id;
      }
    }
  }

  /**
   * to search for available category
   * @param event search string
   */
  searchCategory(event) {
    this.stylesService.searchCategory(event.query, this.createStyles.factoryId).subscribe((response: any) => {
      const categoryAvailable = [];
      this.catList = response.data;
      for (const catOptions of response.data) {
        catOptions.name = catOptions.name ? catOptions.name : 'User';
        categoryAvailable.push(catOptions.name);
      }
      this.categoryListsOptions = categoryAvailable;
    });
  }

  checkValue(styleId: number, status: boolean) {
    this.stylesService.statusStyle(styleId, status).subscribe((response: any) => {
      this.toastr.success(response.message, 'Success');
    });
  }

  /**
   * to get Id of selected category
   * @param event selected option
   */
  captureCategoryId(event: any) {
    for (let i = 0; i < this.catList.length; i++) {
      if (this.catList[i].name === event) {
        this.createStyles.category = event;
        this.createStyles.categoryId = this.catList[i].id;
      }
    }
  }

  /**
   * get factory size grid
   */
  getFactorySizeGrid(): void {
    this.stylesService.getSizeGrid(this.createStyles.factoryId).subscribe(
      result => {
        this.sizeGridData = result;
        this.sizeGridData = this.sizeGridData.data;
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * update sizeId
   */
  updateGridId(size, index) {
    if (this.lastSelectedIndex >= 0) {
      this.sizeGridData[this.lastSelectedIndex].selected = false;
    }
    size.selected = true;
    this.sizeGridId = size.id;
    this.sizeGridName = size.gridValue;
    this.getSize(size.id);
    this.lastSelectedIndex = index;
  }

  /**
   * add size
   */
  addSize() {
    // const createsizeData: any = '';
    if (this.sizeValue) {
      this.createsizeData.sizeGridId = this.sizeGridId;
      this.createsizeData.sizeGridName = this.sizeGridName;
      this.createsizeData.sizeValue = this.sizeValue;
      this.stylesService.createSize(this.createsizeData).subscribe(
        result => {
          this.sizeData = result;
          this.getSize(this.sizeGridId);
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    }
  }

  /**
   * get size grid
   */
  getSize(sizeId) {
    this.stylesService.getAvailableSize(sizeId).subscribe(
      result => {
        this.availableSizeGrid = result;
        this.availableSizeGrid = this.availableSizeGrid.data;
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * to get Id of selected range
   * @param event selected option
   */
  captureSizeGridId(event: any) {
    for (let i = 0; i < this.sizeList.length; i++) {
      if (this.sizeList[i].gridValue === event) {
        this.sizeGridId = this.sizeList[i].id;
      }
    }
  }

  /**
   * to search for available category
   * @param event search string
   */
  searchSizeGrid(event) {
    this.stylesService.searchSizeGrid(event.query, this.createStyles.factoryId).subscribe((response: any) => {
      const categoryAvailable = [];
      this.sizeList = response.data;
      for (const sizeOptions of response.data) {
        sizeOptions.gridValue = sizeOptions.gridValue ? sizeOptions.gridValue : 'No Data Found';
        categoryAvailable.push(sizeOptions.gridValue);
      }
      this.sizeGridOptions = categoryAvailable;
    });
  }

  /**
   * to get Id of selected customer
   * @param event selected option
   */
  captureCustomerId(event: any) {
    for (let i = 0; i < this.sizeList.length; i++) {
      if (this.sizeList[i].name === event) {
        this.createStyles.customerId = this.sizeList[i].id;
      }
      this.getCustomersBrand(this.createStyles.customerId);
    }
  }

  /**
   * to search for available customer
   * @param event search string
   */
  searchCustomer(event) {
    this.stylesService.searchCustomer(event.query, this.createStyles.factoryId).subscribe((response: any) => {
      const categoryAvailable = [];
      this.sizeList = response.data;
      for (const customerOptions of response.data) {
        customerOptions.name = customerOptions.name ? customerOptions.name : 'No Data Found';
        categoryAvailable.push(customerOptions.name);
      }
      this.customerOptions = categoryAvailable;
    });
  }
  /**
   * to get brands associated to customer
   * @param custId is used to fetch brand of specific customer
   */
  getCustomersBrand(custId) {
    this.stylesService.getBrands(custId).subscribe(
      result => {
        this.availableBrands = result;
        this.availableBrands = this.availableBrands.data;
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * to create style
   */
  oncreateStyle(styleform: NgForm) {
    this.createStyles.documentIds = this.uploadedImages.map(x => x.id);
    this.createStyles.styleId = +this.createStyles.styleId;
    this.createStyles.brandId = +this.createStyles.brandId;
    this.createStyles.categoryId = +this.createStyles.categoryId;
    this.createStyles.customerId = +this.createStyles.customerId;
    this.createStyles.factoryId = +this.createStyles.factoryId;
    this.createStyles.rangeId = +this.createStyles.rangeId;
    this.createStyles.sellingPrice = +this.createStyles.sellingPrice;
    this.createStyles.sizeGridId = +this.sizeGridId;
    this.stylesService.createStyle(this.createStyles).subscribe(
      result => {
        styleform.reset();
        this.toastr.success(result.message, 'Success');
        this.getStyleList(1,8);
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  onEditStyle(editStyleform: NgForm): void {
    this.createStyles.documentIds = [];
    if (this.uploadedImages.length > 0) {
      this.createStyles.documentIds = this.uploadedImages.map(x => x.id);
    }
    if (this.documentId) {
      this.createStyles.documentIds.push(this.documentId);
    }
    this.createStyles.styleId = +this.createStyles.styleId;
    this.createStyles.brandId = +this.createStyles.brandId;
    this.createStyles.categoryId = +this.createStyles.categoryId;
    this.createStyles.customerId = +this.createStyles.customerId;
    this.createStyles.factoryId = +this.createStyles.factoryId;
    this.createStyles.rangeId = +this.createStyles.rangeId;
    this.createStyles.sellingPrice = +this.createStyles.sellingPrice;
    this.createStyles.sizeGridId = +this.sizeGridId;
    if (this.styleModalType === 'Edit Style') {
      this.stylesService.updateStyle(this.createStyles, this.createStyles.styleId).subscribe(
        result => {
          editStyleform.reset();
          this.toastr.success(result.message, 'Success');
          this.getStyleList(1,8);
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    } else {
      this.stylesService.copyStyle(this.createStyles, this.createStyles.styleId).subscribe(
        result => {
          editStyleform.reset();
          this.toastr.success(result.message, 'Success');
          this.getStyleList(1,8);
        },
        error => {
          this.toastr.error(error.error.message, 'Error');
        }
      );
    }
  }

  getImageDataAfterFileUpload(ev) {
  }

  onFileUploadProgress() {
    this.spinner = true;
  }

  onFileUploadError() {
    this.spinner = false;
  }

  /**
   * delete size grid
   */
  deleteSizeGrid(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        this.stylesService.deleteSizeGrid(id).subscribe((response: any) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          this.getFactorySizeGrid();
          this.toastr.success(response.message, 'Success');
        }, (error) => {
          this.toastr.error(error.error.message, 'Error');
        });
      }
    });
  }

  /**
   * edit size grid modal
   */
  openSizeGridModal(data) {
    data.factoryId = this.createStyles.factoryId;
    this.editSieGrid = data;
    this.ngxSmartModalService.getModal('editSizeGrid').open();
  }

  /**
   * edit size grid
   */
  editSizegrid() {
    this.stylesService.updateSizeGrid(this.editSieGrid, this.editSieGrid.id).subscribe((response: any) => {
      this.getFactorySizeGrid();
      this.ngxSmartModalService.getModal('editSizeGrid').close();
      this.editSieGrid = {};
      this.toastr.success(response.message, 'Success');
    });
  }

  /**
   * delete size
   */
  deleteSize(sizeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        this.stylesService.deleteSize(sizeId).subscribe((response: any) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          this.getSize(this.sizeGridId);
          this.toastr.success(response.message, 'Success');
        }, (error) => {
          this.toastr.success(error.error.message, 'Error');
        });
      }
    });
  }

  /**
   * openSizeEditModal
   */
  openSizeEditModal(data) {
    data.sizeGridId = this.sizeGridId;
    data.sizeDescription = '';
    this.editSizeData = data;
    this.ngxSmartModalService.getModal('editSize').open();
  }

  editSizes() {
    const id = this.editSizeData.id;
    delete this.editSizeData.id;
    this.stylesService.updateSize(this.editSizeData, id).subscribe((response: any) => {
      this.getSize(this.sizeGridId);
      this.ngxSmartModalService.getModal('editSize').close();
      this.editSizeData = {};
      this.toastr.success(response.message, 'Success');
    });
  }

  /**
   * delete Range
   */
  deleteRange(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        this.stylesService.deleteRange(id).subscribe((response: any) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          this.getRange();
          this.toastr.success(response.message, 'Success');
        }, (error) => {
          this.toastr.error(error.error.message, 'Error');
        });
      }
    });
  }

  /**
   * edit range
   */
  editRange(range) {
    this.isEditRange = true;
    this.rangeName = range.name;
    this.editRangeData.rangeId = range.id;
  }

  /**
   * reset range
   */
  resetRange() {
    this.isEditRange = false;
    this.rangeName = '';
  }

  /**
   * delete Category
   */
  deleteCategory(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        this.stylesService.deleteCategory(id).subscribe((response: any) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          this.getCategory();
          this.toastr.success(response.message, 'Success');
        }, (error) => {
          this.toastr.error(error.error.message, 'Error');
        });
      }
    });
  }

  /**
   * edit category
   */
  editCategory(category) {
    this.isEditCategory = true;
    this.categoryName = category.name;
    this.categoryId = category.id;
  }

  /**
   * reset category
   */
  resetCategory() {
    this.isEditCategory = false;
    this.categoryName = '';
  }

  /**
   * TO open techpack
   */
  openFolderUploadModal(styleData) {
    this.uploadTechPack['styleId'] = styleData.id;
    this.uploadedImages = [];
    for (const iterator of styleData.documents) {
      if (iterator.documentType === 'DOCUMENT') {
        this.uploadedImages.push(iterator);
      }
    }
    this.ngxSmartModalService.getModal('myModal14').open();
  }

  /**
   * on doc upload
   */
  onDocumentUpload(ev) {
    const response = JSON.parse(ev.xhr.response);
    this.spinner = false;
    this.uploadedImages.push(response.data);
  }

  /**
   * Set AUTH token for file upload
   * @param event
   */
  setAuthToken(event) {
    this.spinner = true;
    const authToken = localStorage.getItem('authenticationToken');
    event.xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
  }

  /**
   * To upload Document 
   */
  UploadFiles() {
    console.log(this.uploadedImages);
    this.uploadTechPack['documentIds'] = this.uploadedImages.map(x => x.id);
    this.stylesService.uploadTechPack(this.uploadTechPack).subscribe((response)=>{
      console.log(response);
      this.toastr.success('Uploaded Successfully', 'Success');
      this.getStyleList(1,8);
    });
  }

  /**
   * delete document
   */
  deleteDocument(item) {
    this.stylesService.removeTechPack(this.uploadTechPack.styleId, item.id).subscribe((response: any) => {
      this.uploadedImages.splice(this.uploadedImages.indexOf(item.id), 1);
      this.toastr.success(response.message, 'Success');
      this.getStyleList(1,8);
    });
  }

  /**
   * To search style
   */
  debounceSearch(event) {
    this.spinner = true;
    this.stylesService.searchStyle(event, this.optionSelected).subscribe((response: any) => {
       this.userStylesList = response.data;
       this.totalStyleCardData = response.totalCount;
       this.spinner = false;
    });
  }
}
