const orderTableCol = [
    { field: 'description', header: 'Style Number' },
    { field: 'operation_number', header: 'Order Number' },
    { field: 'std_time', header: 'Status'},
    { field: 'std_time', header: 'Order Date'},
    { field: 'std_time', header: 'FileHandover Date'},
    { field: 'std_time', header: 'Shipment Date'},
    { field: 'std_time', header: 'Delivery Date'},
    { field: 'std_time', header: 'Ex Factory Date'}
];
export {orderTableCol};

export class ColorTable {
}
