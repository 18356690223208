import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
// import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_themes from '@amcharts/amcharts4/themes/kelly.js';
import kelly from '@amcharts/amcharts4/themes/kelly.js';
import { SharedService } from '../shared/shared.service';
import { DashboardService } from './dashboard.service';
import { Subscription } from 'rxjs';
import {
  cardData, approveUnapprove, costingManual,
  lastApprovedChartData, stageWiseData, styleWiseData, userChartData, prodVsCostSamYears ,totalTabs
} from './dashboard.model';
import { NgxSmartModalService } from 'ngx-smart-modal';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
  providers: [DashboardService]
})
export class DashboardComponent implements OnInit, OnDestroy {
  dataForDashboard: any = {};
  dashboardCards = [
    {
      heading: 'Total Operation', description: '0',
      color: 'linear-gradient(to right, #ffcccc -4%, #9999ff 100%)',
      image: 'totalOperations'
    },
    {
      heading: 'Total Analyze', description: 0,
      color: ' linear-gradient(to right, #F39A81 -4%, #FB9678 100%)',
      image: 'savedOperations'
    },
    {
      heading: 'Send for Approval', description: 0,
      color: 'linear-gradient(to right, #FFD75B  -4%, #FFC000 100%)',
      image: 'sentForApproval'
    },
    {
      heading: 'Disapprove', description: 0,
      color: ' linear-gradient(to left, rgb(244, 67, 60) -4%, rgba(245, 155, 133, 1) 100%)',
      image: 'approved'
    },
    {
      heading: 'Approved', description: 0,
      color: ' linear-gradient(to left, #009933 -4%, #66ff66 100%)',
      image: 'approved'
    },
  ];
  totalOperations: number;
  proAnalysis: am4charts.RadarChart;
  analzeData: any;
  approveUnapprove: any;
  costingManualData: any;
  proVSCostingModel: any[];
  userChartData: any;
  mobileView: boolean;
  mobileAuthToken: string;
  mobileFactoryId: number;
  mobileType: number;
  lastApprovedChartData: any[] = [];
  spinner = false;
  stageWiseData: any[] = [];
  dashboardBroadcast: Subscription;
  styleWiseData: any;
  prodVsCostSamYears = prodVsCostSamYears;
  activeTab : number ;
  display: boolean = false;
  totalColTabs : any[];
  dashboardCardData: any;
  cardHeading: string;

  constructor(
    public sharedService: SharedService,
    public dashboardService: DashboardService,
    public activatedRoute: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
  ) {
    // this.dataForDashboard = cardData;
    this.analzeData = cardData;
    console.log(prodVsCostSamYears);
    this.dashboardCards[0].description = `${cardData.totalOperation} Operations Require Action`;
    this.dashboardCards[1].description = cardData.savedCount;
    this.dashboardCards[2].description = cardData.sendApprovalCount;
    this.dashboardCards[3].description = cardData.rejectCount;
    this.dashboardCards[4].description = cardData.approvedCount;
    this.approveUnapprove = approveUnapprove;
    this.costingManualData = costingManual;
    this.lastApprovedChartData = lastApprovedChartData;
    this.stageWiseData = stageWiseData;
    this.styleWiseData = styleWiseData;
    this.userChartData = userChartData;
  }

  ngOnInit() {
    this.spinner = true;
    this.createAnalysisChart();
    this.createProSMVChart();
    this.prodvsCostChart();
    this.createLatstApproved();
    this.createStageChart();
    this.createStyleChart();
    this.creatUserWiseChart();
    this.initalProductionCostingData();
    this.productionVsCostingChart();
    this.totalColTabs = totalTabs;
    if (this.activatedRoute.snapshot.data.page === 'Dashboard Mobile') {
      this.mobileView = true;
      this.mobileAuthToken = this.activatedRoute.snapshot.queryParamMap.get('token');
      this.mobileFactoryId = +this.activatedRoute.snapshot.queryParamMap.get('factoryId');
      this.mobileType = +this.activatedRoute.snapshot.queryParamMap.get('type');
      localStorage.setItem('mobileView', JSON.stringify(true));
      localStorage.setItem('mobileToken', this.mobileAuthToken);
    } else {
      localStorage.removeItem('mobileView');
      localStorage.removeItem('mobileToken');
    }
    am4core.useTheme(am4themes_themes);
    am4core.useTheme(am4themes_animated);
    this.initalProductionCostingData();
    am4core.useTheme(am4themes_animated);

    this.createRefernceChart1();
    this.createReferenceChart2();
    if (this.mobileView) {
      this.dataForDashboard.factoryId = this.mobileFactoryId;
      this.dataForDashboard.type = this.mobileType;
      this.getChartsData(this.dashboardService, this.dataForDashboard);
    } else {
      this.dashboardBroadcast = this.sharedService.dashboard$.subscribe((status) => {
        this.dataForDashboard = status;
        this.getChartsData(this.dashboardService, this.dataForDashboard);
      });
    }
  }

  ngOnDestroy() {
    this.dashboardBroadcast.unsubscribe();
  }
  tabSelected(indexValue, cardHeading){
    this.cardHeading = cardHeading;
    this.activeTab = indexValue + 1;
      let dashboardData = {
        factoryId : this.dataForDashboard.factoryId,
        type : this.dataForDashboard.type
      };
      this.getCardData(dashboardData,this.activeTab);
      this.ngxSmartModalService.getModal(`myModal1`).open();
    
  }

  getChartsData(service, dashboardData) {
    this.dataForDashboard.year = new Date().getFullYear();
    this.spinner = true;
    this.getCardsData(service, dashboardData);
    this.getDataProSMV(service, dashboardData);
    this.getManualvsCosting(service, dashboardData);
    this.productionCosting(service, dashboardData);
    this.getUserWiseData(service, dashboardData);
    this.getApprovedLast(service, dashboardData);
    this.getStageWise(service, dashboardData);
    this.getStyleWise(service, dashboardData);
  }

  /**
   * initial data
   */
  initalProductionCostingData() {
    this.proVSCostingModel = [{
      monthName: 'January',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'February',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'March',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'April',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'May',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'June',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'July',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'August',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'September',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'October',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'November',
      totalProduction: 0,
      totalCosting: 0,
    }, {
      monthName: 'December',
      totalProduction: 0,
      totalCosting: 0,
    }];
  }

  /**
   * getCardsData
   */
  getCardsData(service, dashboardData) {
    this.spinner = true;
    service.getCardsDetails(dashboardData).subscribe((response: any) => {
      this.analzeData = response.data;
      this.createAnalysisChart();
      this.dashboardCards[0].description = `${response.data.totalOperation} Operations Require Action`;
      this.dashboardCards[1].description = response.data.savedCount;
      this.dashboardCards[2].description = response.data.sendApprovalCount;
      this.dashboardCards[3].description = response.data.rejectCount;
      this.dashboardCards[4].description = response.data.approvedCount;
    });
  }
  createReferenceChart2() {
    // create chart
    const refChart = am4core.create('referenceChart2', am4charts.GaugeChart);
    refChart.innerRadius = -15;

    const axis = refChart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis.min = 0;
    axis.max = 100;
    axis.strictMinMax = true;

    const colorSet = new am4core.ColorSet();

    const gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color('red') });
    gradient.stops.push({ color: am4core.color('yellow') });
    gradient.stops.push({ color: am4core.color('green') });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.grid.template.disabled = true;

    const hand = refChart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    setInterval(() => {
      hand.showValue(Math.random() * 100, 1000, am4core.ease.cubicOut);
    }, 2000);
  }

  createRefernceChart1() {
    // Create chart instance
    const refChart = am4core.create('referenceChart1', am4charts.XYChart3D);

    // Add data
    refChart.data = [
      {
        'year': 2005,
        'income': 23.5,
        'color': refChart.colors.next()
      }, {
        'year': 2006,
        'income': 26.2,
        'color': refChart.colors.next()
      }, {
        'year': 2007,
        'income': 30.1,
        'color': refChart.colors.next()
      }, {
        'year': 2008,
        'income': 29.5,
        'color': refChart.colors.next()
      }, {
        'year': 2009,
        'income': 24.6,
        'color': refChart.colors.next()
      }];

    // Create axes
    const refChartcategoryAxis = refChart.yAxes.push(new am4charts.CategoryAxis());
    refChartcategoryAxis.dataFields.category = 'year';
    refChartcategoryAxis.numberFormatter.numberFormat = '#';
    refChartcategoryAxis.renderer.inversed = true;

    const refChartvalueAxis = refChart.xAxes.push(new am4charts.ValueAxis());

    // Create series
    const refChartseries = refChart.series.push(new am4charts.ColumnSeries3D());
    refChartseries.dataFields.valueX = 'income';
    refChartseries.dataFields.categoryY = 'year';
    refChartseries.name = 'Income';
    refChartseries.columns.template.propertyFields.fill = 'color';
    refChartseries.columns.template.tooltipText = '{valueX}';
    refChartseries.columns.template.column3D.stroke = am4core.color('#fff');
    refChartseries.columns.template.column3D.strokeOpacity = 0.2;
  }

  createAnalysisChart() {
    const proAnalysis = am4core.create('analysisChart', am4charts.RadarChart);

    // Add data
    proAnalysis.data = [{
      category: 'Approved',
      value: this.analzeData.approvedCount ? (this.analzeData.approvedCount * 100) /
        this.analzeData.totalOperation : 0,
      full: 100
    }, {
      category: 'Sent for Approval',
      value: this.analzeData.sendApprovalCount ? (this.analzeData.sendApprovalCount * 100) /
        this.analzeData.totalOperation : 0,
      full: 100
    }, {
      category: 'Saved',
      value: this.analzeData.savedCount ? (this.analzeData.savedCount * 100) /
        this.analzeData.totalOperation : 0,
      full: 100
    }, {
      category: 'Total',
      value: this.analzeData.totalOperation ? 100 : 0,
      full: 100
    }];

    // Make chart not full circle
    proAnalysis.startAngle = -90;
    proAnalysis.endAngle = 180;
    proAnalysis.innerRadius = am4core.percent(20);

    // Set number format
    proAnalysis.numberFormatter.numberFormat = '#.#\'%\'';

    // Create axes
    const categoryAxis1 = proAnalysis.yAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererRadial>());
    categoryAxis1.dataFields.category = 'category';
    categoryAxis1.renderer.grid.template.location = 0;
    categoryAxis1.renderer.grid.template.strokeOpacity = 0;
    categoryAxis1.renderer.labels.template.horizontalCenter = 'right';
    // categoryAxis.renderer.labels.template.fontWeight = 500;
    categoryAxis1.renderer.labels.template.adapter.add('fill', (fill, target) => {
      return (target.dataItem.index >= 0) ? proAnalysis.colors.getIndex(target.dataItem.index) : fill;
    });
    categoryAxis1.renderer.minGridDistance = 10;

    const proAnalysisvalueAxis1 = proAnalysis.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    proAnalysisvalueAxis1.renderer.grid.template.strokeOpacity = 0;
    proAnalysisvalueAxis1.min = 0;
    proAnalysisvalueAxis1.max = 100;
    proAnalysisvalueAxis1.strictMinMax = true;

    // Create series
    const proAnalysisseries1 = proAnalysis.series.push(new am4charts.RadarColumnSeries());
    proAnalysisseries1.dataFields.valueX = 'full';
    proAnalysisseries1.dataFields.categoryY = 'category';
    proAnalysisseries1.clustered = false;
    proAnalysisseries1.columns.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
    proAnalysisseries1.columns.template.fillOpacity = 0.08;
    // proAnalysisseries1.columns.template.cornerRadiusTopLeft = 20;
    proAnalysisseries1.columns.template.strokeWidth = 0;
    proAnalysisseries1.columns.template.radarColumn.cornerRadius = 20;

    const proAnalysisseries2 = proAnalysis.series.push(new am4charts.RadarColumnSeries());
    proAnalysisseries2.dataFields.valueX = 'value';
    proAnalysisseries2.dataFields.categoryY = 'category';
    proAnalysisseries2.clustered = false;
    proAnalysisseries2.columns.template.strokeWidth = 0;
    proAnalysisseries2.columns.template.tooltipText = '{category}: [bold]{value}[/]';
    proAnalysisseries2.columns.template.radarColumn.cornerRadius = 20;

    proAnalysisseries2.columns.template.adapter.add('fill', (fill, target) => {
      return proAnalysis.colors.getIndex(target.dataItem.index);
    });
    proAnalysis.cursor = new am4charts.RadarCursor();
    this.spinner = false;
  }

  getDataProSMV(service, dashboardData) {
    this.spinner = true;
    service.getProSMV(dashboardData).subscribe((response: any) => {
      this.approveUnapprove = response.data;
      this.createProSMVChart();
    });
  }

  createProSMVChart() {
    // tslint:disable-next-line:max-line-length
    const iconPath = `M421.976,136.204h-23.409l-0.012,0.008c-0.19-20.728-1.405-41.457-3.643-61.704l-1.476-13.352H5.159L3.682,74.507 C1.239,96.601,0,119.273,0,141.895c0,65.221,7.788,126.69,22.52,177.761c7.67,26.588,17.259,50.661,28.5,71.548  c11.793,21.915,25.534,40.556,40.839,55.406l4.364,4.234h206.148l4.364-4.234c15.306-14.85,29.046-33.491,40.839-55.406  c11.241-20.888,20.829-44.96,28.5-71.548c0.325-1.127,0.643-2.266,0.961-3.404h44.94c49.639,0,90.024-40.385,90.024-90.024  C512,176.588,471.615,136.204,421.976,136.204z M421.976,256.252h-32c3.061-19.239,5.329-39.333,6.766-60.048h25.234  c16.582,0,30.024,13.442,30.024,30.024C452,242.81,438.558,256.252,421.976,256.252z`
    const ProSMVChart = am4core.create('proSMV', am4charts.SlicedChart);
    const total = this.approveUnapprove.totalOperation ? this.approveUnapprove.totalOperation : 0;
    const approved = this.approveUnapprove.approvedCount ? this.approveUnapprove.approvedCount : 0;
    ProSMVChart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    ProSMVChart.paddingLeft = 0;
    ProSMVChart.data = [{
      name: 'Unusage',
      value: total - approved,
      disabled: true
    }, {
      name: 'Usage',
      value: approved,
    }];
    const ProSMVChartseries = ProSMVChart.series.push(new am4charts.PictorialStackedSeries());
    ProSMVChartseries.dataFields.value = 'value';
    ProSMVChartseries.dataFields.category = 'name';
    ProSMVChartseries.alignLabels = true;
    ProSMVChartseries.labels.template.propertyFields.disabled = 'disabled';
    ProSMVChartseries.ticks.template.propertyFields.disabled = 'disabled';
    ProSMVChartseries.maskSprite.path = iconPath;
    ProSMVChartseries.ticks.template.locationX = 1;
    ProSMVChartseries.ticks.template.locationY = 0;
    ProSMVChartseries.labelsContainer.width = 100;
    ProSMVChartseries.labelsContainer.height = 100;
    ProSMVChart.legend = new am4charts.Legend();
    ProSMVChart.legend.position = 'top';
    ProSMVChart.legend.paddingRight = 10;
    ProSMVChart.legend.paddingBottom = 10;
    const ProSMVChartmarker = ProSMVChart.legend.markers.template.children.getIndex(0);
    ProSMVChart.legend.markers.template.width = 20;
    ProSMVChart.legend.markers.template.height = 20;
    this.spinner = false;
  }

  getManualvsCosting(service, dashboardData) {
    this.spinner = true;
    service.getProCostSAM(dashboardData).subscribe((response: any) => {
      this.costingManualData = response.data;
      this.prodvsCostChart();
    });
  }

  prodvsCostChart() {
    // const totalOPChart = am4core.create('totalOPChart', am4charts.GaugeChart);
    const costingChart = am4core.create('totalOPChart', am4charts.GaugeChart);
    costingChart.hiddenState.properties.opacity = 0;

    const costingChartaxis = costingChart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    costingChartaxis.min = 0;
    costingChartaxis.max = this.costingManualData.totalCosting + 20;
    costingChartaxis.strictMinMax = true;
    costingChartaxis.renderer.inside = true;
    costingChartaxis.renderer.radius = am4core.percent(97);
    costingChartaxis.renderer.line.strokeOpacity = 1;
    costingChartaxis.renderer.line.strokeWidth = 5;
    costingChartaxis.renderer.line.stroke = costingChart.colors.getIndex(0);
    costingChartaxis.renderer.ticks.template.stroke = costingChart.colors.getIndex(0);
    costingChartaxis.renderer.labels.template.radius = 35;
    costingChartaxis.renderer.ticks.template.strokeOpacity = 1;
    costingChartaxis.renderer.grid.template.disabled = true;
    costingChartaxis.renderer.ticks.template.length = 10;
    costingChartaxis.hiddenState.properties.opacity = 1;
    costingChartaxis.hiddenState.properties.visible = true;
    costingChartaxis.setStateOnChildren = true;
    costingChartaxis.renderer.hiddenState.properties.endAngle = 180;

    const costingChartaxis2 = costingChart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    costingChartaxis2.min = 0;
    costingChartaxis2.max = this.costingManualData.totalManual + 20;
    costingChartaxis2.strictMinMax = true;

    costingChartaxis2.renderer.line.strokeOpacity = 1;
    costingChartaxis2.renderer.line.strokeWidth = 5;
    costingChartaxis2.renderer.line.stroke = costingChart.colors.getIndex(3);
    costingChartaxis2.renderer.ticks.template.stroke = costingChart.colors.getIndex(3);

    costingChartaxis2.renderer.ticks.template.strokeOpacity = 1;
    costingChartaxis2.renderer.grid.template.disabled = true;
    costingChartaxis2.renderer.ticks.template.length = 10;
    costingChartaxis2.hiddenState.properties.opacity = 1;
    costingChartaxis2.hiddenState.properties.visible = true;
    costingChartaxis2.setStateOnChildren = true;
    costingChartaxis2.renderer.hiddenState.properties.endAngle = 180;

    const hand = costingChart.hands.push(new am4charts.ClockHand());
    hand.fill = costingChartaxis.renderer.line.stroke;
    hand.stroke = costingChartaxis.renderer.line.stroke;
    hand.axis = costingChartaxis;
    hand.pin.radius = 14;
    hand.startWidth = 10;
    hand.showValue(this.costingManualData.totalCosting);

    const hand2 = costingChart.hands.push(new am4charts.ClockHand());
    hand2.fill = costingChartaxis2.renderer.line.stroke;
    hand2.stroke = costingChartaxis2.renderer.line.stroke;
    hand2.axis = costingChartaxis2;
    hand2.pin.radius = 10;
    hand2.startWidth = 10;
    hand2.showValue(this.costingManualData.totalManual);

    const legend = new am4charts.Legend();
    legend.isMeasured = false;
    legend.y = am4core.percent(0);
    legend.verticalCenter = 'top';
    legend.parent = costingChart.chartContainer;
    legend.data = [{
      'name': 'Costing SAM',
      'fill': costingChart.colors.getIndex(0)
    }, {
      'name': 'Production SAM',
      'fill': costingChart.colors.getIndex(3)
    }];

    const labelList = new am4core.ListTemplate(new am4core.Label());
    labelList.template.isMeasured = false;
    labelList.template.background.strokeWidth = 2;
    labelList.template.fontSize = 25;
    labelList.template.padding(10, 20, 10, 20);
    labelList.template.y = am4core.percent(80);
    labelList.template.horizontalCenter = 'middle';

    legend.itemContainers.template.events.on('hit', (ev) => {
      const index = ev.target.dataItem.index;

      if (!ev.target.isActive) {
        costingChart.hands.getIndex(index).hide();
        costingChart.xAxes.getIndex(index).hide();
        labelList.getIndex(index).hide();
      } else {
        costingChart.hands.getIndex(index).show();
        costingChart.xAxes.getIndex(index).show();
        labelList.getIndex(index).show();
      }
    });

    const label = labelList.create();
    label.parent = costingChart.chartContainer;
    label.x = am4core.percent(40);
    label.background.stroke = costingChart.colors.getIndex(0);
    label.fill = costingChart.colors.getIndex(0);
    label.text = this.costingManualData.totalCosting.toString();

    const label2 = labelList.create();
    label2.parent = costingChart.chartContainer;
    label2.x = am4core.percent(60);
    label2.background.stroke = costingChart.colors.getIndex(3);
    label2.fill = costingChart.colors.getIndex(3);
    label2.text = this.costingManualData.totalManual.toString();
    this.spinner = false;
  }

  productionCosting(service, dashboardData) {
    this.spinner = true;
    this.initalProductionCostingData();
    service.getProductionCosting(dashboardData).subscribe((response: any) => {
      if (response.data) {
        for (const item of response.data) {
          for (const data of this.proVSCostingModel) {
            if (data.monthName === item.monthName) {
              data.totalCosting = item.totalCosting;
              data.totalProduction = item.totalProduction;
            }
          }
        }
      } else {
        this.initalProductionCostingData();
      }
      this.productionVsCostingChart();
    });
  }

  productionVsCostingChart() {
    const prodvsCostingSam = am4core.create('prodvsCostingSam', am4charts.XYChart);
    prodvsCostingSam.data = this.proVSCostingModel;

    // Create category axis
    const prodvsCostingSamcategoryAxis = prodvsCostingSam.xAxes.push(new am4charts.CategoryAxis());
    prodvsCostingSamcategoryAxis.dataFields.category = 'monthName';
    prodvsCostingSamcategoryAxis.renderer.minGridDistance = 10;
    // Create value axis
    const prodvsCostingSamvalueAxis = prodvsCostingSam.yAxes.push(new am4charts.ValueAxis());
    // prodvsCostingSamvalueAxis.title.text = 'Place taken';
    prodvsCostingSamvalueAxis.renderer.minGridDistance = 10;
    prodvsCostingSamvalueAxis.renderer.minLabelPosition = 0.01;

    // Create series
    const prodvsCostingSamseries1 = prodvsCostingSam.series.push(new am4charts.LineSeries());
    prodvsCostingSamseries1.dataFields.valueY = 'totalProduction';
    prodvsCostingSamseries1.dataFields.categoryX = 'monthName';
    prodvsCostingSamseries1.name = 'Average Production SAM';
    prodvsCostingSamseries1.strokeWidth = 1;
    prodvsCostingSamseries1.bullets.push(new am4charts.CircleBullet());
    prodvsCostingSamseries1.tooltipText = '{name} in {categoryX}: {valueY}';
    prodvsCostingSamseries1.legendSettings.valueText = '{valueY}';
    prodvsCostingSamseries1.visible = false;

    const prodvsCostingSamseries2 = prodvsCostingSam.series.push(new am4charts.LineSeries());
    prodvsCostingSamseries2.dataFields.valueY = 'totalCosting';
    prodvsCostingSamseries2.dataFields.categoryX = 'monthName';
    prodvsCostingSamseries2.name = 'Average Costing SAM';
    prodvsCostingSamseries2.strokeWidth = 1;
    prodvsCostingSamseries2.bullets.push(new am4charts.CircleBullet());
    prodvsCostingSamseries2.tooltipText = '{name} in {categoryX}: {valueY}';
    prodvsCostingSamseries2.legendSettings.valueText = '{valueY}';

    // Add chart cursor
    prodvsCostingSam.cursor = new am4charts.XYCursor();
    prodvsCostingSam.cursor.behavior = 'zoomY';

    // Add legend
    prodvsCostingSam.legend = new am4charts.Legend();
    this.spinner = false;
  }

  getUserWiseData(service, dashboardData) {
    service.getUserWiseData(dashboardData).subscribe((response: any) => {
      this.userChartData = response.data;
      this.creatUserWiseChart();
    });
  }


  creatUserWiseChart() {
    const userChart = am4core.create('methodImprovChart', am4charts.XYChart);
    userChart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    userChart.data = this.userChartData;
    const userChartcategoryAxis = userChart.xAxes.push(new am4charts.CategoryAxis());
    userChartcategoryAxis.renderer.grid.template.location = 0;
    userChartcategoryAxis.dataFields.category = 'name';
    userChartcategoryAxis.renderer.minGridDistance = 40;
    const userChartvalueAxis = userChart.yAxes.push(new am4charts.ValueAxis());
    const userChartseries = userChart.series.push(new am4charts.CurvedColumnSeries());
    userChartseries.dataFields.categoryX = 'name';
    userChartseries.dataFields.valueY = 'difference';
    userChartseries.tooltipText = '{valueY.difference}';
    userChartseries.columns.template.strokeOpacity = 0;
    userChartseries.columns.template.tension = 1;
    userChartseries.columns.template.fillOpacity = 0.75;
    const userCharthoverState = userChartseries.columns.template.states.create('hover');
    userCharthoverState.properties.fillOpacity = 1;
    userCharthoverState.properties.tension = 0.8;
    userChart.cursor = new am4charts.XYCursor();
    userChartseries.columns.template.adapter.add('fill', (fill, target) => {
      return userChart.colors.getIndex(target.dataItem.index);
    });

  }

  /**
   * Approved inlast 3 moths data
   */
  getApprovedLast(service, dashboardData) {
    service.getApprvedLastData(dashboardData).subscribe((response: any) => {
      this.lastApprovedChartData = response.data;
      this.lastApprovedChartData = this.lastApprovedChartData.slice(0, 3);
      this.createLatstApproved();
    });
  }

  createLatstApproved() {
    const approval = am4core.create('approvedChart', am4charts.XYChart);

    // Add data
    approval.data = this.lastApprovedChartData;
    const categoryAxis = approval.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'month';
    categoryAxis.title.text = '';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    const valueAxis = approval.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = '';

    function createSeries(field, name, stacked) {
      const series = approval.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'month';
      series.name = name;
      series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
      series.stacked = stacked;
      series.columns.template.width = am4core.percent(95);
    }
    createSeries('approvedCount', 'Approved', false);
    createSeries('unApprovedCount', 'Unapproved', false);
    approval.legend = new am4charts.Legend();
    approval.legend.position = 'top';
  }

  /**
   * get stage wise chart data
   */
  getStageWise(service, dashboardData) {
    service.getstageWiseData(dashboardData).subscribe((response: any) => {
      this.stageWiseData = response.data;
      this.createStageChart();
    });
  }

  /**
   * create stage wise chart
   */
  createStageChart() {
    const stageChart = am4core.create('distanceChart', am4charts.XYChart);
    stageChart.data = this.stageWiseData;
    const stageChartcategoryAxis = stageChart.xAxes.push(new am4charts.CategoryAxis());
    stageChartcategoryAxis.dataFields.category = 'stage';
    stageChartcategoryAxis.renderer.grid.template.location = 0;
    stageChartcategoryAxis.renderer.minGridDistance = 30;
    const stageChartvalueAxis = stageChart.yAxes.push(new am4charts.ValueAxis());
    const stageChartseries = stageChart.series.push(new am4charts.ColumnSeries());
    stageChartseries.dataFields.valueY = 'count';
    stageChartseries.dataFields.categoryX = 'stage';
    stageChartseries.name = 'count';
    stageChartseries.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    stageChartseries.columns.template.fillOpacity = .8;
    const stageChartcolumnTemplate = stageChartseries.columns.template;
    stageChartcolumnTemplate.strokeWidth = 2;
    stageChartcolumnTemplate.strokeOpacity = 1;
  }

  /**
   * get style wise chart data
   */
  getStyleWise(service, dashboardData) {
    service.getStyleWiseData(dashboardData).subscribe((response: any) => {
      this.styleWiseData = response.data;
      for (const style of this.styleWiseData) {
        style['styleName'] = style.name;
        delete style.name;
      }
      this.createStyleChart();
    });
  }

  /**
   * get Card wise data
   */
   getCardData(dashboardData,cardNo) {
     this.spinner = true;
     this.dashboardService.getCardData(dashboardData,cardNo).subscribe((response: any) => {
      this.dashboardCardData = response.data;
      this.spinner = false;
    });
  }

  createStyleChart() {
    const chart = am4core.create('stylechart', am4charts.XYChart);
    // Add data
    chart.data = this.styleWiseData;
    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'styleName';

    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = '';
    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = 'totalStyleCount';
    series1.dataFields.categoryX = 'styleName';
    series1.yAxis = valueAxis1;
    series1.name = 'Total Operation';
    series1.tooltipText = 'Total Operation : [bold font-size: 20]{valueY}[/]';
    series1.legendSettings.valueText = '{valueY}';
    series1.fill = chart.colors.getIndex(0).lighten(0.5);
    series1.strokeWidth = 0;
    series1.clustered = false;
    series1.toBack();
    const series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = 'savedStyleCount';
    series2.dataFields.categoryX = 'styleName';
    series2.yAxis = valueAxis1;
    series2.name = 'Total Analysed';
    series2.tooltipText = 'Total Analysed : [bold font-size: 20]{valueY}[/]';
    series2.legendSettings.valueText = '{valueY}';
    series2.fill = chart.colors.getIndex(0);
    series2.strokeWidth = 0;
    series2.clustered = false;
    series2.columns.template.width = am4core.percent(40);



    const series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = 'videoCount';
    series3.dataFields.categoryX = 'styleName';
    series3.name = 'Video Attached';
    series3.strokeWidth = 2;
    series3.tensionX = 0.7;
    series3.yAxis = valueAxis1;
    series3.tooltipText = 'Video Attached : [bold font-size: 20]{valueY}[/]';
    series3.legendSettings.valueText = '{valueY}';

    const bullet3 = series3.bullets.push(new am4charts.CircleBullet());
    bullet3.circle.radius = 3;
    bullet3.circle.strokeWidth = 2;
    bullet3.circle.fill = am4core.color('#fff');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
  }
}
