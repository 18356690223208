const threadConsumptionColumns = [
    { field: 'operation_number', header: 'OP No.' },
    { field: 'description', header: 'Description' },
    { field: 'std_time', header: 'Std. Min' },
    { field: 'machine', header: 'Machine' },
    { field: 'seam_length', header: 'Seam Length' },
    { field: 'totalthread', header: 'Total Thread' },
    { field: 'upperthread', header: 'Upper Thread' },
    { field: 'looperthread', header: 'Looper Thread' },
    { field: 'thread_quality', header: 'Thread Quality' },
    { field: 'stitchclass', header: 'Stich Class' },
    { field: 'needlethreadratio', header: 'Needle Thread' },
    { field: 'looperthreadratio', header: 'Looper Thread' },
    { field: 'needlecount', header: 'No of Needle' },
];
const OrderthreadConsumptionColumns = [
    { field: 'order_number', header: 'Order No.' },
    { field: 'color_code', header: 'Color' },
    { field: 'order_quantity', header: 'Quantity' },
    { field: 'totalcms', header: 'Total (CM)' },
    { field: 'ppm', header: 'Total (Meter)' },
    { field: 'allowance', header: 'Meter(Allowance)' },
    { field: 'totalms', header: 'Thread (Meter)' },
    { field: 'thread_quality', header: 'Thread Quality' }
];
const machineThreadCols = [
    { field: 'name', header: 'Machine'},
    { field: 'thread_quality', header: 'Thread'}
];
export { machineThreadCols };
export {threadConsumptionColumns};
export {OrderthreadConsumptionColumns};
