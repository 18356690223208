import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipInY, bounce, bounceIn, rollIn } from 'ng-animate';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FactoryService } from './factory.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-factory',
  templateUrl: './factory.component.html',
  animations: [
    trigger('flip', [transition('* => *', useAnimation(flipInY))]),
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('rollIn', [transition('* => *', useAnimation(rollIn))])
  ],
  styleUrls: ['./factory.component.sass']
})
export class FactoryComponent implements OnInit {
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public dateValue: Object = new Date(new Date().setDate(14));
  // public minDate: Object = new Date(this.currentYear, this.currentMonth, 1);
  // public maxDate: Object =  new Date(this.currentYear, this.currentMonth, 27);
  public format = 'dd/MM/yyyy';

  displayMode = 1;
  flip: any;
  bounce: any;
  bounceIn: any;
  rollIn: any;
  factoryListDTO: any = true;
  createFactory: any = {};
  FactoryUpdate: any = {};
  NgxSmartModalService: any;
  isChecked: any;
  url = '';
  result: any;
  resultsFactory: Array<any> = [];
  resultsRole: Array<any> = [];
  results: Array<any> = [];
  companyId: any;
  coList: any;
  roleList: any;
  factoryList: any;
  optionSelected: string;
  searchText: any;
  createUser: any = {};
  uploadImgUrl: string;
  imageSrc: any;
  documentId: any;
  isSuperAdmin: string;
  factAuthData: any = {};

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private factoryService: FactoryService,
    private toastr: ToastrService,
    private service: FactoryService,
    public httpClient: HttpClient,
    public sharedService: SharedService
  ) {
    this.optionSelected = 'name';
    this.createFactory.name = null;
    this.createFactory.licenseExpDate = null;
    this.createFactory.licenseIssueDate = null;
    this.createFactory.membership = null;
    this.createFactory.mobileNumber = null;
    this.createFactory.email = null;
    this.createFactory.address = null;
    this.createFactory.membership = null;
    this.createFactory.status = true;
    this.createFactory.companyId = this.companyId;

    this.createUser.companyId = this.companyId;
    this.createUser.name = null;
    this.createUser.username = null;
    this.createUser.password = null;
    this.createUser.confirmPassword = null;
    this.createUser.mobileNumber = null;
    this.createUser.email = null;
    this.createUser.roleId = this.resultsFactory;
    // this.createUser.factoryId = this.resultsRole;
    this.createUser.factoryNames = [];
    this.createUser.active = true;
  }
  ngOnInit() {
    this.getAuthData();
    this.getFactoryList();
    this.isSuperAdmin = localStorage.getItem('isSuperAdmin');
    this.uploadImgUrl = `${window.location.origin}/apigateway/admin/api/v1/upload/companyLogo`;
  }

  /**
   * fetch role and permissions assigned to it
   */
  getAuthData() {
    const auth = this.sharedService.authData$.subscribe((data: any) => {
      const authData = data.find((el) => {
        return el.name === 'File & Setup';
      });
      const factAuthData = authData.subModules.find((el) => {
        return el.subModuleName === 'Factory';
      });
      this.factAuthData = factAuthData;
    });
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getFactoryList() {
    this.factoryService.getFactory().subscribe((response: any) => {
      if (response.data) {
        this.factoryListDTO = response.data;
      } else {
      }
    });
  }

  search(event) {
    this.service.search(event.query).subscribe((response: any) => {
      const companyList = [];
      for (const comp of response.data) {
        comp.name = comp.name;
        companyList.push(comp.name);
        // this.companyId = comp.id;
      }
      this.results = companyList;
      this.coList = response.data;
    });
  }

  captureId(event: any) {
    for (var i = 0; i < this.coList.length; i++) {
      if (this.coList[i].name === event) {
        this.companyId = this.coList[i].id;
      }
    }
  }

  searching(event) {
    this.companyId = this.createUser.companyId;
    this.service.searching(event.query, this.companyId).subscribe((response: any) => {
      const roleList = [];
      for (const role of response.data) {
        role.roleName = role.roleName;
        roleList.push(role.roleName);
        // this.companyId = comp.id;
      }
      this.resultsFactory = roleList;
      this.roleList = response.data;
    });
  }

  captureRoleId(event: any) {
    for (var i = 0; i < this.roleList.length; i++) {
      if (this.roleList[i].roleName === event) {
        this.resultsFactory = this.roleList[i].id;
      }
    }
  }

  searchFactory(event) {
    this.service.searchFactory(event.query).subscribe((response: any) => {
      const factoryList = [];
      for (const factory of response.data) {
        factory.name = factory.name;
        factoryList.push(factory.name);
        // this.companyId = comp.id;
      }
      this.resultsRole = factoryList;
      this.factoryList = response.data;
    });
  }

  captureFactoryId(event: any) {
    for (var i = 0; i < this.factoryList.length; i++) {
      if (this.factoryList[i].name === event) {
        this.resultsRole = this.factoryList[i].id;
      }
    }
    this.createUser.factoryNames.push(event);
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.httpClient.post<any>(this.uploadImgUrl, formData).subscribe(
        (res) => {
          this.imageSrc = res.data.path;
          this.documentId = res.data.id;
        },
      );
    }
  }

  deleteFactory(list) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value === true) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        this.factoryService.deleteFactory(list.id).subscribe((response: any) => {
          const index: number = this.factoryListDTO.indexOf(list);
          this.factoryListDTO.splice(index, 1);
          this.toastr.success(response.message, 'Success');
        });
      }
    });
  }

  checkValue(factoryId: number, status: boolean) {
    this.factoryService.statusFactory(factoryId, status).subscribe((response: any) => {
      this.toastr.success('Factory Status Updated', 'Success');
    });
  }

  private openUpdateFactoryModal(data) {
    this.FactoryUpdate.name = data.name;
    this.FactoryUpdate.factoryId = data.id;
    this.FactoryUpdate.licenseExpDate = data.licenseExpDate;
    this.FactoryUpdate.licenseIssueDate = data.licenseIssueDate;
    this.FactoryUpdate.membership = data.membership;
    this.FactoryUpdate.mobileNumber = data.mobileNumber;
    this.FactoryUpdate.email = data.email;
    this.FactoryUpdate.companyName = data.company.name;
    this.FactoryUpdate.companyId = data.company.id;
    this.FactoryUpdate.address = data.address;
    this.FactoryUpdate.status = data.active;
    this.ngxSmartModalService.getModal('myModal1').open(data);
  }

  openNewFactoryModal() {
    this.imageSrc = null;
    this.documentId = null;
    this.ngxSmartModalService.getModal('myModal').open();
  }

  public openNewUserModal(data) {
    const factoryList = [];
    this.ngxSmartModalService.getModal('myModal3').open(data);
    this.createUser.companyName = data.company.name;
    this.createUser.factoryNames.push(data.name);
    factoryList.push(data.name);
    this.createUser.companyId = data.company.id;
  }

  onUserCreate(form: NgForm) {
    this.createUser.companyId = this.companyId;
    this.createUser.roleId = this.resultsFactory;
    this.factoryService.createUser(this.createUser).subscribe(
      result => {
        form.reset();
        this.ngxSmartModalService.getModal('myModal3').close();
        this.getFactoryList();
        this.toastr.success(result.message, 'Success');
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }
  /**
   * reset create company modal
   */
  resetCreateFactory() {
    this.ngxSmartModalService.getModal('myModal').close();
    this.imageSrc = null;
    this.documentId = null;
  }

  onFactoryCreate(form: NgForm) {
    this.createFactory.companyId = this.companyId;
    this.createFactory.documentId = this.documentId;
    this.factoryService.createFactory(this.createFactory).subscribe(
      result => {
        form.reset();
        // window.location.reload();
        this.toastr.success(result.message, 'Success');
        this.getFactoryList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }
  onFactoryUpdate(form: NgForm) {
    this.FactoryUpdate.documentId = this.documentId;
    this.factoryService.updateFactory(this.FactoryUpdate).subscribe(
      result => {
        form.reset();
        this.ngxSmartModalService.getModal('myModal1').close();
        this.toastr.success(result.message, 'Success');
        this.getFactoryList();
      },
      error => {
        this.toastr.error(error.error.message, 'Error');
      }
    );
  }

  /**
   * update date on changing membership type
   * @param membershipType trial or permanent
   */
  updateDates(membershipType) {
    this.createFactory.licenseIssueDate = new Date();
    this.createFactory.licenseExpDate = new Date();
    this.FactoryUpdate.licenseIssueDate = new Date();
    this.FactoryUpdate.licenseExpDate = new Date();
    if (membershipType === 'trial') {
      // this.createFactory.licenseExpDate = new Date();
      this.createFactory.licenseExpDate.setMonth(new Date().getMonth() + 1);
      this.FactoryUpdate.licenseExpDate.setMonth(new Date().getMonth() + 1);
    } else {
      this.createFactory.licenseExpDate.setMonth(new Date().getMonth() + 12);
      this.FactoryUpdate.licenseExpDate.setMonth(new Date().getMonth() + 12);
    }
  }
}
