import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpclientService } from '../shared/httpclient.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  model: any = {};
  result: any = {};
  spinner = false;
  otpVerify : number;
  otpNumber: any;
  showOTP = false;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private httpClientService: HttpclientService
  ) {
  }

  ngOnInit() {
    this.clearToken();
  }

  clearToken() {
    window.localStorage.clear();
  }

  login() {
    this.spinner = true;
    const data = {
      username: this.model.username,
      password: this.model.password
    };

    this.loginUser(data).subscribe((response: any) => {
        this.spinner = false;
         this.toastr.success(response.message, 'OTP SENT SUCCESSFULLY');
         this.showOTP = true;
      
    }, (error) => {
      this.spinner = false;
      this.toastr.error(error.error.message, 'Unauthorised');
    });
  }
  otpVerification(){
      this.spinner = true;
      const verifyData = {
        username: this.model.username,
        otp: this.otpVerify
      };
      this.otpVerifyData(verifyData).subscribe((response: any) => {
        this.spinner = false;
        if (response.data.authenticationToken != null) {
          localStorage.setItem('authenticationToken', response.data.authenticationToken);
          localStorage.setItem('userType', response.data.user.userType);
          localStorage.setItem('username', response.data.user.username);
          localStorage.setItem('chatToken', response.data.user.matrixAccessToken);
          localStorage.setItem('roleId', response.data.user.roleId);
          localStorage.setItem('loggedInUserId', response.data.user.matrixUserId);
          localStorage.setItem('isSuperAdmin', response.data.user.isSuperAdmin ?
            response.data.user.isSuperAdmin : false);
            this.router.navigate(['dashboard']);
          this.toastr.success(response.data.message, 'OTP Verified');
          
        }
      }, (error) => {
        this.spinner = false;
        this.toastr.error(error.error.message, 'Incorrect OTP');
      });
  }

  loginUser(data): Observable<any[]> {
    const url = `${window.location.origin}/apigateway/login/api/v1/user/login`;
    return this.postData(url, data);
  }
  otpVerifyData(data): Observable<any[]>{
    const url = `${window.location.origin}/apigateway/login/api/v1/validateOtp`;
    return this.postData(url, data);
  }

  postData(url, data?): Observable<any> {
    return this.httpClientService.postLogin(url, data);
  }
}
