const operationsColumns = [
    { field: 'opNo', header: 'OP No.' },
    { field: 'desc', header: 'Description' },
    { field: 'machine', header: 'Machine' },
    { field: 'sLength', header: 'S. L.' },
    { field: 'stdMin', header: 'SMV' },
    { field: 'workAid', header: 'Work Aid' },
    { field: 'status', header: 'Status' },
    { field: 'group', header: 'Grade' },
    { field: 'skill', header: 'Skill' },
    { field: 'action', header: 'Action' },
    { field: 'material', header: 'Material' },
    { field: 'skill', header: 'Product' },
    { field: 'skill', header: 'Part' },
    { field: 'grade', header: 'OP type' },
    // { field: 'createdBy', header: 'CreatedBy' }
];

export {operationsColumns};
