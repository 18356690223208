const cardData = {
    approvedCount: 0,
    rejectCount: 0,
    savedCount: 0,
    sendApprovalCount: 0,
    totalOperation: 0,
};
const approveUnapprove = {
    approvedCount: 0,
    totalOperation: 0
};
const costingManual = { totalCosting: 0, totalManual: 0 };

const lastApprovedChartData =
    [{ month: 'September', approvedCount: 0, unApprovedCount: 0 },
    { month: 'October', approvedCount: 0, unApprovedCount: 0 },
    { month: 'November', approvedCount: 0, unApprovedCount: 0 }];

const stageWiseData = [
    { stage: 'Costing', count: 0 },
    { stage: 'Fit', count: 0 },
    { stage: 'PP', count: 0 },
    { stage: 'Size Set', count: 0 },
    { stage: 'Production', count: 0 }
];
const styleWiseData = [
    { styleName: 'style1', videoCount: 0, savedStyleCount: 0, totalStyleCount: 0 },
    { styleName: 'style2', videoCount: 0, savedStyleCount: 0, totalStyleCount: 0 },
    { styleName: 'style3', videoCount: 0, savedStyleCount: 0, totalStyleCount: 0 },
];
const userChartData = [
    { name: 'user01', difference: 0 },
];
const currentYear = new Date().getFullYear();
const prodVsCostSamYears = [
    `${currentYear - 2}`,
    `${currentYear - 1}`,
    `${currentYear}`,
    `${currentYear + 1}`,
    `${currentYear + 2}`,
];
const totalTabs = [
    { field:"opno" , header: "OP No."},
    { field:"description" , header: "Description"},
    { field:"machine" , header: "Machine"},
    { field:"sl" , header: "S.L."},
    { field:"smv" , header: "SMV"},
    { field:"workAid" , header: "Work Aid"},
    { field:"status" , header: "Status"},
    { field:"grade" , header: "Grade"},
    { field:"styles" , header: "No. of Styles"},
    { field:"createdBy" , header: "Created By"},
    { field:"updatedBy" , header: "Updated By"},
    { field:"updatedDate" , header: "Updated Date"}
];
export { cardData };
export { approveUnapprove };
export { costingManual };
export { lastApprovedChartData };
export { stageWiseData };
export { styleWiseData };
export {userChartData};
export {prodVsCostSamYears};
export { totalTabs };
